import { io, Socket } from 'socket.io-client';
import { DefaultEventsMap } from '@socket.io/component-emitter';
import { message } from 'antd';
import { SOURCES_WEB_SOCKET_URL } from '../../../../../configs/env';
import { startAsycCubeDataExport } from '../../../helpers/helpers';
import { Dictionary } from '@onaio/utils';
import { QueryExportParams } from '../../../../../configs/component-types';

export const checkExportProgress = async (
  jobId: string,
  socket: Socket<DefaultEventsMap, DefaultEventsMap> | undefined,
  setCsvDownloadUrl: (url: string) => void,
  setLoading: (loadingState: boolean) => void
): Promise<void> => {
  socket?.on(
    jobId,
    (data: {
      progress: {
      stage: string;
      data: Dictionary[];
      errors: Dictionary[];
    };
  }) => {
    if (data?.progress?.stage === 'DONE' && data?.progress?.errors.length === 0) {
      setCsvDownloadUrl(data?.progress?.data[0]?.downloadUrl as string);
      setLoading(false);
      socket?.disconnect();
    }
    if (data?.progress?.errors.length !== 0) {
      message.error(data?.progress?.errors[0]?.message || 'Error exporting data, please try again', 4);
      setLoading(false);
      socket?.disconnect();
    }
  });
};

export const triggerAsycDataExport = (
  query: QueryExportParams,
  setCsvDownloadUrl: (url: string) => void,
  setLoading: (loadingState: boolean) => void
): void => {
  const socket = io(SOURCES_WEB_SOCKET_URL, {
    path: '/v1/socket.io',
    withCredentials: true,
  });
  socket.on('connect', () => {
    // start asyc task for export source query data
    startAsycCubeDataExport({
      queryExportParams: query,
    }).then(async (res) => {
      const data = res as Dictionary;
      await checkExportProgress(data.job_uuid, socket, setCsvDownloadUrl, setLoading);
    });
    // eslint-disable-next-line no-console
    console.log(
      `client connected to websock server ${SOURCES_WEB_SOCKET_URL}, socket id: ${socket.id}`
    );
  });
  socket.on('connect_error', () => {
    setTimeout(() => socket.connect(), 5000);
  });
  socket.on('disconnect', () => {
    // eslint-disable-next-line no-console
    console.log(`client disconnected from websock server ${SOURCES_WEB_SOCKET_URL}`);
  });
};
