import React, { RefObject } from 'react';
import { Dictionary } from '@onaio/utils';
import {
  Dimension,
  Measure,
  Source,
  QueryExportParams,
  csvSourceParams,
  MySQLSourceTypeRequestPayload,
  ClickHouseSourceTypeRequestPayload,
  SourceRefreshScheduleRequestPayload,
  SourceRefreshRequestPayload,
  geometryCreateRequestPayload,
  geometryUpdateRequestPayload,
  googleSheetSourceParams,
  postgresSQLSourceParams,
  S3ParquetSourceParams,
  parquetSourceFromFileParams,
  sourceGeometryFromPMTilesFileParams,
  sourceGeometryFromPMTilesServerParams,
  updateS3UserSecretsForS3ParquetSourceParams,
  geoParquetSourceFromFileParams,
  geoParquetSourceFromUrlParams,
  updateSourceCubeSchemaRequestPayload,
  updateSourceDimensionsRequestPayload,
  updateSourceMeasuresRequestPayload,
  deleteSourceCubeDimensionsRequestPayload,
  deleteSourceCubeMeasuresRequestPayload,
  csvSourceFromFileCreationParams,
  csvSourceFromFileUpdateParams,
} from '../../../configs/component-types';
import { AkukoAPIService } from '../../../services/serviceClass';
import { SOURCES_API } from '../../../configs/env';
import { ColumnType, FilterConfirmProps, FilterDropdownProps } from 'antd/lib/table/interface';
import { Button, Input, InputRef, Space } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';

export const getSanitizedName = (name: string): string => {
  return name.replace(/[^A-Z0-9]/gi, '_').toLowerCase();
};

export interface SearchUtilsProps {
  searchText: string;
  setSearchText: (text: string) => void;
  searchedColumn: string;
  setSearchedColumn: (column: string) => void;
  searchInput: RefObject<InputRef>;
}

export const getType = (type: string): string => {
  if (type === 'number') {
    return 'float';
  }
  if (type === 'time') {
    return 'date';
  }
  return 'string';
};

export const transformCubeDimensionsAndMeasuresToSourceUIFormat = (
  dimensions: Dictionary[],
  measures: Dictionary[]
): Dictionary => {
  let updatedDimensions = [...dimensions];
  let updatedMeasures = [...measures];

  if (updatedDimensions && updatedDimensions.length !== 0) {
    updatedDimensions = Object.keys(updatedDimensions[0]).map((dimensionName) => {
      return {
        value: dimensionName,
        title: updatedDimensions[0][dimensionName].title,
        type: updatedDimensions[0][dimensionName].type,
        prefix: updatedDimensions[0][dimensionName].meta?.prefix,
        suffix: updatedDimensions[0][dimensionName].meta?.suffix,
        format: updatedDimensions[0][dimensionName].meta?.format,
        custom: updatedDimensions[0][dimensionName].meta?.custom,
      };
    });
  }
  if (updatedMeasures && updatedMeasures.length !== 0) {
    updatedMeasures = Object.keys(updatedMeasures[0]).map((measureName) => {
      return {
        name: measureName,
        type: updatedMeasures[0][measureName].type,
        sql: updatedMeasures[0][measureName].sql,
        title: updatedMeasures[0][measureName].title,
        prefix: updatedMeasures[0][measureName].meta?.prefix,
        suffix: updatedMeasures[0][measureName].meta?.suffix,
        format: updatedMeasures[0][measureName].meta?.format,
        filters: updatedMeasures[0][measureName].filters,
      };
    });
  }
  return { dimensions: updatedDimensions, measures: updatedMeasures };
};

export const transformSourceUIDimensionsAndMeasuresToCubeFormat = ({
  dimensions,
  measures,
  cubeDimensions = [],
  cubeMeasures = [],
}: {
  dimensions: Dimension[];
  measures: Measure[];
  cubeDimensions: Dictionary[];
  cubeMeasures: Dictionary[];
}): Dictionary => {
  const schemaDimensions = cubeDimensions.length ? { ...cubeDimensions[0] } : {};
  const schemaMeasures = cubeMeasures.length ? { ...cubeMeasures[0] } : {};
  if (dimensions && dimensions.length !== 0) {
    dimensions.map((dimension: Dimension) => {
      schemaDimensions[dimension.value] = {
        ...schemaDimensions[dimension.value],
        title: dimension.title,
        type: dimension.type,
      };
      /**
        For new sources when no cubeDimensions and cubeMeasures exist
        Create cube sql key and assign it the dimension.value (dimension name)
      **/
      if (cubeDimensions.length === 0 && cubeMeasures.length === 0) {
        schemaDimensions[dimension.value].sql = dimension.value;
      }
      if (dimension.prefix !== undefined) {
        schemaDimensions[dimension.value].meta = {
          ...schemaDimensions[dimension.value].meta,
          prefix: dimension.prefix,
        };
      }
      if (dimension.suffix !== undefined) {
        schemaDimensions[dimension.value].meta = {
          ...schemaDimensions[dimension.value].meta,
          suffix: dimension.suffix,
        };
      }
      if (dimension.format !== undefined) {
        schemaDimensions[dimension.value].meta = {
          ...schemaDimensions[dimension.value].meta,
          format: dimension.format,
        };
      }
      if (dimension.sql !== undefined) {
        schemaDimensions[dimension.value].sql = dimension.sql;
      }
      if (dimension.custom !== undefined) {
        schemaDimensions[dimension.value].meta = {
          ...schemaDimensions[dimension.value].meta,
          custom: dimension.custom,
        };
      }
    });
  }
  if (measures && measures.length !== 0) {
    measures.forEach((measure: Measure) => {
      schemaMeasures[measure.name] = {
        ...schemaMeasures[measure.name],
        type: measure.type,
        sql: measure.sql,
        title: measure.title,
        filters: measure.filters,
      };
      if (measure.prefix !== undefined) {
        schemaMeasures[measure.name].meta = {
          ...schemaMeasures[measure.name].meta,
          prefix: measure.prefix,
        };
      }
      if (measure.suffix !== undefined) {
        schemaMeasures[measure.name].meta = {
          ...schemaMeasures[measure.name].meta,
          suffix: measure.suffix,
        };
      }
      if (measure.format !== undefined) {
        schemaMeasures[measure.name].meta = {
          ...schemaMeasures[measure.name].meta,
          format: measure.format,
        };
      }
    });
  }
  return { dimensions: [schemaDimensions], measures: [schemaMeasures] };
};

interface Column {
  title: string;
  dataIndex: string;
  key: number;
}

interface TableData {
  columns: Column[];
  rows: Dictionary[];
}

const handleSearch = (
  selectedKeys: string[],
  confirm: (param?: FilterConfirmProps) => void,
  dataIndex: string,
  setSearchText: (text: string) => void,
  setSearchedColumn: (text: string) => void
) => {
  confirm();
  setSearchText(selectedKeys[0]);
  setSearchedColumn(dataIndex);
};

const handleReset = (
  clearFilters: () => void,
  setSearchText: (text: string) => void,
  setSearchedColumn: (text: string) => void,
  dataIndex: string,
  confirm: (param?: FilterConfirmProps) => void
) => {
  clearFilters();
  setSearchText('');
  setSearchedColumn(dataIndex);
  confirm();
};

const getColumnSearchProps = (
  dataIndex: string,
  searchUtils: SearchUtilsProps
): ColumnType<Dictionary> => {
  const { searchText, setSearchText, searchedColumn, setSearchedColumn, searchInput } = searchUtils;
  return {
    // eslint-disable-next-line react/display-name
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }: FilterDropdownProps) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() =>
            handleSearch(
              selectedKeys as string[],
              confirm,
              dataIndex,
              setSearchText,
              setSearchedColumn
            )
          }
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() =>
              handleSearch(
                selectedKeys as string[],
                confirm,
                dataIndex,
                setSearchText,
                setSearchedColumn
              )
            }
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() =>
              clearFilters &&
              handleReset(clearFilters, setSearchText, setSearchedColumn, dataIndex, confirm)
            }
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText((selectedKeys as string[])[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    // eslint-disable-next-line react/display-name
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    /* @ts-ignore */
    onFilter: (value: string | number | boolean, record: Dictionary) => {
      return record?.[dataIndex as string | number]
        ?.toString()
        ?.toLowerCase()
        ?.includes((value as string).toLowerCase());
    },
    onFilterDropdownOpenChange: (visible: boolean) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    // eslint-disable-next-line react/display-name
    render: (text: string | number) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  };
};

export const buildCsvFromSourceCubeQueryData = ({
  data,
  cube,
}: {
  data: Dictionary[];
  cube: string;
}) => {
   const headers = Object.keys(data[0] || {}).map(
    key => key.split(`${cube}.`)[1]
   );

   const csvRows = [
    headers.join(','),
    ...data.map(row => 
      headers.map(header => {
        const cell = row[`${cube}.${header}`]?.toString() || '';
        return cell.includes(',') ? `"${cell}"` : cell;
      }).join(',')
    )
  ];

  return csvRows.join('\n');
}

export const getTableDataFromCubeQueryResults = (
  data: Dictionary[],
  source: Source,
  searchUtils: SearchUtilsProps
): TableData => {
  const columns: Column[] = [];
  const columnNames: string[] = [];
  const rows: Dictionary[] = [];

  if (data && data.length !== 0) {
    data.forEach((obj: Dictionary, index: number) => {
      if (index === 0) {
        Object.keys(obj).forEach((key, index) => {
          const columnName = key.split(`${source.cube}.`)[1];
          columnNames.push(columnName);
          if (columns.findIndex((column) => column.title === columnName) === -1) {
            columns.push({
              title: columnName as any,
              dataIndex: columnName as any,
              key: index as any,
              ...getColumnSearchProps(columnName, searchUtils),
            });
          }
        });
      }
      const row: Dictionary = { key: index };
      columnNames.forEach((name) => {
        row[name] = obj[`${source.cube}.${name}`];
      });
      rows.push(row);
    });
  }
  return { columns, rows };
};

export const startAsycCubeDataExport = async ({
  queryExportParams,
}: {
  queryExportParams: QueryExportParams;
}): Promise<Dictionary> => {
  const service = new AkukoAPIService(
    SOURCES_API,
    `export/${queryExportParams?.cube_query?.query?.uuid}`
  );
  const res = await service.create({ ...queryExportParams });
  return res as Dictionary;
};

export const startAsycSourceCreationFromUrl = async ({
  sourceId,
  url,
  sourceType,
  refresh,
  name,
  tippecanoe_options,
}: csvSourceParams): Promise<Dictionary> => {
  const service = new AkukoAPIService(
    SOURCES_API,
    `source/${sourceType}/url/${sourceId}?refresh=${refresh}&name=${name}&tippecanoe_options=${tippecanoe_options}`
  );
  const res = await service.update({ url: url });
  return res as Dictionary;
};

export const startAsycGoogleSheetSourceCreation = async ({
  sourceId,
  url,
  sheet,
  type,
  name,
  cube,
}: googleSheetSourceParams): Promise<Dictionary> => {
  const service = new AkukoAPIService(
    SOURCES_API,
    `google-sheet/create`
  );
  const res = await service.create({
    id: sourceId,
    url: url,
    sheet: sheet,
    type: type,
    name: name,
    cube: cube,
  });
  return res as Dictionary;
};

export const startAsycPostgresSQLSourceCreation = async ({
  sourceId,
  type,
  name,
  cube,
  schema,
  table,
  user,
  host,
  database,
  password,
  port,
}: postgresSQLSourceParams): Promise<Dictionary> => {
  const service = new AkukoAPIService(
    SOURCES_API,
    `postgres/create`
  );
  const res = await service.create({
    id: sourceId,
    type: type,
    name: name,
    cube: cube,
    schema: schema,
    table: table,
    user: user,
    host: host,
    database: database,
    password: password,
    port: port,
  });
  return res as Dictionary;
};

export const startAsycS3ParquetSourceCreation = async ({
  sourceId,
  cube_name,
  aws_s3_details
}: S3ParquetSourceParams): Promise<Dictionary> => {
  const service = new AkukoAPIService(
    SOURCES_API,
    `parquet/aws/s3`
  );
  const res = await service.create({
    source_id: sourceId,
    cube_name: cube_name,
    aws_s3_details: aws_s3_details,
  });
  return res as Dictionary;
};

export const updateS3UserSecretsForS3ParquetSource = async ({
  sourceId,
  awsS3Credentials
}: updateS3UserSecretsForS3ParquetSourceParams): Promise<Dictionary> => {
  const service = new AkukoAPIService(
    SOURCES_API,
    `parquet/aws/s3/secrets/${sourceId}`
  );
  const res = await service.update({
    aws_s3_credentials: awsS3Credentials,
  });
  return res as Dictionary;
};

export const startAsycSourceUpdateFromFile = async (
  payload: csvSourceParams
): Promise<Dictionary> => {
  const service = new AkukoAPIService(
    SOURCES_API,
    `source/${payload?.sourceType}/file/${payload?.sourceId}?refresh=${payload?.refresh}&name=${payload?.name}&tippecanoe_options=${payload?.tippecanoe_options}`,
    payload?.onUploadProgress
  );
  const res = await service.update(payload?.formData as FormData);
  return res as Dictionary;
};

export const startAsycCsvSourceCreationFromFile = async (
  payload: csvSourceFromFileCreationParams
): Promise<Dictionary> => {
  const service = new AkukoAPIService(
    SOURCES_API,
    `source/csv/file/${payload?.sourceId}?name=${payload?.name}`,
    payload?.onUploadProgress
  );
  const res = await service.create(payload?.formData as FormData);
  return res as Dictionary;
};

export const startAsycCsvSourceUpdateFromFile = async (
  payload: csvSourceFromFileUpdateParams
): Promise<Dictionary> => {
  const service = new AkukoAPIService(
    SOURCES_API,
    `source/csv/file/${payload?.sourceId}`,
    payload?.onUploadProgress
  );
  const res = await service.update(payload?.formData as FormData);
  return res as Dictionary;
};

export const createParquetSourceFromFile = async (
  payload: parquetSourceFromFileParams
): Promise<Dictionary> => {
  const service = new AkukoAPIService(
    SOURCES_API,
    `parquet/file/${payload?.sourceId}?cube_name=${payload?.cube_name}`,
    payload?.onUploadProgress
  );
  const res = await service.update(payload?.formData as FormData);
  return res as Dictionary;
};

export const createGeoParquetSourceFromUrl = async (
  payload: geoParquetSourceFromUrlParams
): Promise<Dictionary> => {
  const service = new AkukoAPIService(
    SOURCES_API,
    `geoparquet/url/${payload?.sourceId}`
  );
  const res = await service.create({
    cubeName: payload?.cubeName,
    geoParquetUrl: payload?.geoParquetUrl,
    tippecanoeOptions: payload?.tippecanoeOptions,
  });
  return res as Dictionary;
};

export const createGeoParquetSourceFromFile = async (
  payload: geoParquetSourceFromFileParams
): Promise<Dictionary> => {
  const service = new AkukoAPIService(
    SOURCES_API,
    `geoparquet/file/${payload?.sourceId}?cubeName=${payload?.cubeName}&tippecanoe_options=${payload?.tippecanoe_options}`,
    payload?.onUploadProgress
  );
  const res = await service.create(payload?.formData as FormData);
  return res as Dictionary;
};

export const createSourceGeometryFromPMTilesFile = async (
  payload: sourceGeometryFromPMTilesFileParams
): Promise<Dictionary> => {
  const service = new AkukoAPIService(
    SOURCES_API,
    `geometry/pmtiles/file/${payload?.sourceId}?name=${payload?.name}`,
    payload?.onUploadProgress
  );
  const res = await service.create(payload?.formData as FormData);
  return res as Dictionary;
};

export const createSourceGeometryFromPMTilesServer = async (
  payload: sourceGeometryFromPMTilesServerParams
): Promise<Dictionary> => {
  const service = new AkukoAPIService(
    SOURCES_API,
    `geometry/pmtiles/server/${payload?.sourceId}`,
  );
  const res = await service.create({
    name: payload?.name,
    mvtTilesUrl: payload?.mvtTilesUrl,
    layerName: payload?.layerName,
    maxZoom: payload?.maxZoom,
    minZoom: payload?.minZoom,
  });
  return res as Dictionary;
};

export const updateSourceGeometryPropertiesVals = async (
  sourceId: string,
  payload: Dictionary
): Promise<Dictionary> => {
  const service = new AkukoAPIService(
    SOURCES_API,
    `geometry/properties/${sourceId}`,
  );
  const res = await service.update({
    ...payload,
  });
  return res as Dictionary;
};

export const startAsycJobForCreatingDatabaseSourceType = async (
  payload: MySQLSourceTypeRequestPayload
): Promise<Dictionary> => {
  const service = new AkukoAPIService(
    SOURCES_API,
    `source/${payload?.sourceType}/${payload?.sourceId}`
  );
  const res = await service.update(payload?.formData as FormData);
  return res as Dictionary;
};

export const createClickHouseSourceType = async (
  payload: ClickHouseSourceTypeRequestPayload
): Promise<Dictionary> => {
  const service = new AkukoAPIService(
    SOURCES_API,
    `source/clickhouse/${payload?.sourceId}`
  );
  const res = await service.create(payload?.formData as FormData);
  return res as Dictionary;
};

export const updateClickHouseSourceTypeConnectionConfigs = async (
  payload: ClickHouseSourceTypeRequestPayload
): Promise<Dictionary> => {
  const service = new AkukoAPIService(
    SOURCES_API,
    `source/clickhouse/connection/${payload?.sourceId}`
  );
  const res = await service.update(payload?.formData as FormData);
  return res as Dictionary;
};

export const startAsycJobForCreatingGeometry = async (payload: geometryCreateRequestPayload
): Promise<Dictionary> => {
  const service = new AkukoAPIService(
    SOURCES_API,
    `geometry/${payload?.endpointName}/${payload?.sourceId}`,
  );
  const res = await service.create(payload?.payload);
  return res as Dictionary;
};

export const getSource = async ({ sourceId }: { sourceId: string }): Promise<Dictionary> => {
  const sourceFetchService = new AkukoAPIService(SOURCES_API, `source`);
  const response = await sourceFetchService.read(sourceId);
  return response as Dictionary;
};

export const startAsycJobForUpdatingGeometry = async (payload: geometryUpdateRequestPayload
): Promise<Dictionary> => {
  const service = new AkukoAPIService(
    SOURCES_API,
    `geometry/${payload?.endpointName}/${payload?.geometryId}`,
  );
  const res = await service.update(payload?.payload);
  return res as Dictionary;
};

export const startAsycJobForCreatingSourceRefreshSchedule = async ({ sourceId, payload }: SourceRefreshScheduleRequestPayload
): Promise<Dictionary> => {
  const service = new AkukoAPIService(
    SOURCES_API,
    `source/schedules/${sourceId}`,
  );
  const res = await service.create(payload);
  return res as Dictionary;
};

export const startAsycJobForUpdatingSourceRefreshSchedule = async ({
  sourceId,
  payload,
}: SourceRefreshScheduleRequestPayload): Promise<Dictionary> => {
  const service = new AkukoAPIService(SOURCES_API, `source/schedules/${sourceId}`);
  const res = await service.update(payload);
  return res as Dictionary;
};

export const startAsycJobForSourceRefresh = async ({
  sourceId,
}: SourceRefreshRequestPayload): Promise<Dictionary> => {
  const service = new AkukoAPIService(SOURCES_API, `source/refresh/${sourceId}`);
  const res = await service.update({});
  return res as Dictionary;
};

export const downloadCubeQuery = async ({ exportId }: { exportId: string }): Promise<string> => {
  const service = new AkukoAPIService(SOURCES_API, `export`);
  const res = await service.read(exportId);
  return res as string;
};

export const updateSourceCubeSchema = async (
  payload: updateSourceCubeSchemaRequestPayload
): Promise<Dictionary> => {
  const service = new AkukoAPIService(
    SOURCES_API,
    `cube/schema/${payload?.sourceId}`
  );
  const res = await service.update({
    schema: payload?.schema,
    cubeName: payload?.cubeName,
  });
  return res as Dictionary;
};

export const updateSourceDimensions = async (
  payload: updateSourceDimensionsRequestPayload
): Promise<Dictionary> => {
  const service = new AkukoAPIService(
    SOURCES_API,
    `cube/dimensions/${payload?.sourceId}`
  );
  const res = await service.update({
    dimensions: payload?.dimensions,
    cubeName: payload?.cubeName,
  });
  return res as Dictionary;
};

export const updateSourceMeasures = async (
  payload: updateSourceMeasuresRequestPayload
): Promise<Dictionary> => {
  const service = new AkukoAPIService(
    SOURCES_API,
    `cube/measures/${payload?.sourceId}`
  );
  const res = await service.update({
    measures: payload?.measures,
    cubeName: payload?.cubeName,
  });
  return res as Dictionary;
};

export const deleteSourceCubeDimensions = async (
  payload: deleteSourceCubeDimensionsRequestPayload
): Promise<Dictionary> => {
  const service = new AkukoAPIService(
    SOURCES_API,
    `cube/dimensions/${payload?.sourceId}`
  );
  const res = await service.delete(
    null,
    'DELETE',
    {
      dimensionNames: payload?.dimensionNames,
      cubeName: payload?.cubeName,
    });
  return res as Dictionary;
};

export const deleteSourceCubeMeasures = async (
  payload: deleteSourceCubeMeasuresRequestPayload
): Promise<Dictionary> => {
  const service = new AkukoAPIService(
    SOURCES_API,
    `cube/measures/${payload?.sourceId}`
  );
  const res = await service.delete(
    null,
    'DELETE',
    {
      measureNames: payload?.measureNames,
      cubeName: payload?.cubeName,
    });
  return res as Dictionary;
};
