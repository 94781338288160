import React, { useState } from "react";
import { useSelector } from "react-redux";
import { InputNumber, Select, Button, message, Form, Space } from "antd";
import { AkukoAPIService } from "../../../services/serviceClass";
import { AKUKO_APP_RETRY_COUNT, AKUKO_QUERY_API_JWT_TOKEN_HEADER_NAME, QUERY_API } from "../../../configs/env";
import { Dictionary } from "@onaio/utils";
import {
  FilterOutlined,
  LoadingOutlined,
  MinusCircleOutlined,
} from "@ant-design/icons";
import { CubeQueryResultsTable } from "./CubeQueryResultTable";
import {
  fetchDataWithRetry,
  selectOptionsBuilder,
} from "../../Post/Settings/components/DimensionInput/helpers/helpers";
import { OPERATORS, TIME_OPERATORS } from "./constants";
import { BaseOptionType } from "antd/lib/select";
import { generateJWTToken } from "../../Post/utils";
import { buildCsvFromSourceCubeQueryData } from "../helpers/helpers";

const { Option } = Select;

export interface NullOperatorValueProps {
  isNull: string;
  notNull: string;
}

export interface SourceFilterProps {
  filterProperty: string;
  operator: string;
  filterValue: string | string[];
}

const QueryForm: React.FC = () => {
  const source = useSelector((state: Dictionary) => state.source);
  const [dimensions, setDimensions] = useState<string[]>([]);
  const [dimensionValue, setDimensionValue] = useState<Dictionary>({});
  const [measures, setMeasures] = useState<string[]>([]);
  const [operators, setOperators] = useState<Dictionary[]>(OPERATORS);
  const [limit, setLimit] = useState(5000);
  const [result, setResult] = useState<Dictionary[]>();
  const [loading, setLoading] = useState(false);
  const [showFilterValue, setShowFilterValue] = useState(true);
  const filterOptions = [
    ...selectOptionsBuilder(source?.measures, undefined, "measure"),
    ...selectOptionsBuilder(source?.dimensions, undefined, "dimension"),
  ];
  const nullOperatorValues: NullOperatorValueProps = {
    isNull: "notSet",
    notNull: "set",
  };
  const onFinish = (filterValues: any) => {
    const { filters, dimension, measure } = filterValues;

    if (dimension === undefined && measure === undefined) {
      message.error(
        "Query should contain either measures, dimensions or timeDimensions with granularities in order to be valid"
      );
      return true;
    }

    const queryFilters = filters
      ? filters.map((filter: SourceFilterProps) =>
          showFilterValue
            ? {
                member: `${source.cube}.${filter.filterProperty}`,
                operator: filter.operator,
                values: Array.isArray(filter.filterValue)
                  ? filter.filterValue
                  : [filter.filterValue],
              }
            : {
                member: `${source.cube}.${filter.filterProperty}`,
                operator:
                  nullOperatorValues[
                    filter.operator as keyof NullOperatorValueProps
                  ],
              }
        )
      : [];
    setLoading(true);
    const retryUtilityConfigs: Dictionary = {
      dimensions: dimensions,
      measures: measures,
      source: source,
      queryFilters: queryFilters,
      limit: limit,
      setResult: setResult,
      setLoading: setLoading,
    };
    fetchDataWithRetry(AKUKO_APP_RETRY_COUNT, retryUtilityConfigs);
  };

  const downloadCSV = (data: Dictionary[], cube: string) => {
    const csvContent = buildCsvFromSourceCubeQueryData({
      data: data,
      cube: cube,
    });
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', `${new Date().toISOString()}.csv`);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Form onFinish={onFinish}>
      <p>Create a query to test your Source configuration.</p>
      <div className="input-field">
        <Form.Item name="dimension">
          <Select
            placeholder="Dimensions"
            showSearch
            mode={"multiple"}
            onChange={(value: string[]) => {
              if (value.length > 0) {
                const values: string[] = [];
                value.forEach((item: string) => {
                  values.push(`${source.cube}.${item}`);
                });
                setDimensions(values);
              } else {
                setDimensions([]);
              }
            }}
          >
            {source?.dimensions?.map((item: Dictionary, index: number) => (
              <Option key={index} value={item.value}>
                {item.value}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </div>
      <div className="input-field">
        <Form.Item name="measure">
          <Select
            placeholder="Measures"
            showSearch
            mode={"multiple"}
            onChange={(value: string[]) => {
              if (value.length > 0) {
                const values: string[] = [];
                value.forEach((item: string) => {
                  values.push(`${source.cube}.${item}`);
                });
                setMeasures(values);
              } else {
                setMeasures([]);
              }
            }}
          >
            {source?.measures?.map((item: Dictionary, index: number) => (
              <Option key={index} value={item.name}>
                {item.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </div>
      <div className="input-field">
        <Form.List name="filters">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }) => (
                <Space
                  key={key}
                  style={{ display: "flex", marginBottom: 8 }}
                  align="baseline"
                >
                  <Form.Item {...restField} name={[name, "filterProperty"]}>
                    <Select
                      placeholder="Filter Property"
                      allowClear
                      style={{ width: 200 }}
                      showSearch
                      onChange={async (value: string[], e: BaseOptionType) => {
                        if (e?.["data-type"] === "time") {
                          setOperators(TIME_OPERATORS);
                        }
                        if (value) {
                          const dimensions = [`${source.cube}.${value}`];
                          const token = await generateJWTToken({
                            sourceId: source?.uuid,
                            cubeName: source?.cube,
                            refreshKey: source?.refresh_key,
                          });
                          const headers = {
                            [AKUKO_QUERY_API_JWT_TOKEN_HEADER_NAME]: token,
                            'Content-Type': 'application/json'
                          };
                          const service = new AkukoAPIService(QUERY_API, '/cubejs-api/v1/load', undefined, headers);
                          service.create({
                            query: {
                              dimensions: dimensions,
                              measures: [],
                              limit: limit,
                              renewQuery: true,
                          }})
                          .then((res) => {
                            const response = res as Dictionary;
                            const queryResult = response.data as Dictionary[];
                            const responseValues = Array.isArray(queryResult)
                              ? queryResult?.map((record: Dictionary) => {
                                  return record[`${source.cube}.${value}`];
                                })
                              : [];
                            const compositeDimensions = { ...dimensionValue };
                            compositeDimensions[key] = responseValues;
                            setDimensionValue(compositeDimensions);
                          })
                          .catch((err) => {
                            const error = new Error(err as string);
                            message.error(error.message);
                            setLoading(false);
                          });
                        }
                      }}
                    >
                      {filterOptions}
                    </Select>
                  </Form.Item>
                  <Form.Item {...restField} name={[name, "operator"]}>
                    <Select
                      placeholder="Operator"
                      style={{ width: 200 }}
                      showSearch
                      allowClear
                      options={operators}
                      onChange={(value) => {
                        if (["notNull", "isNull"].includes(value)) {
                          setShowFilterValue(false);
                        } else {
                          setShowFilterValue(true);
                        }
                      }}
                    ></Select>
                  </Form.Item>
                  {showFilterValue && (
                    <Form.Item {...restField} name={[name, "filterValue"]}>
                      <Select
                        placeholder="Value"
                        style={{ width: 200 }}
                        showSearch
                        allowClear
                        mode={"multiple"}
                      >
                        {dimensionValue?.[key]?.map(
                          (item: Dictionary, index: number) => (
                            <Option key={index} value={item}>
                              {/* @ts-ignore */}
                              {item}
                            </Option>
                          )
                        )}
                      </Select>
                      {/* <Input placeholder="Value" style={{ width: 200 }} /> */}
                    </Form.Item>
                  )}
                  <MinusCircleOutlined onClick={() => remove(name)} />
                </Space>
              ))}
              <Form.Item>
                <Button onClick={() => add()} icon={<FilterOutlined />}>
                  Add filter
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      </div>
      <div className="input-field">
        <label>Limit</label>
        <InputNumber
          value={limit}
          onChange={(value) => {
            if (value) {
              setLimit(value);
            }
          }}
        />
      </div>
      <Button type="primary" disabled={loading} htmlType="submit">
        {loading && <LoadingOutlined />} Run query
      </Button>
      {result && (
        <>
          {" "}
          <Button 
            type="primary" 
            onClick={() => downloadCSV(result, source?.cube)}
          >
            Download CSV
          </Button>
        </>
      )}
      {result && (
        <div className="source-query">
          <CubeQueryResultsTable data={result} loading={loading} />
        </div>
      )}
    </Form>
  );
};

export { QueryForm };
