import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Dropdown, MenuProps, Button, Space } from 'antd';
import { ExportOutlined, LoadingOutlined, MoreOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { getComponentSource } from '../../../reducers/selectors/post';
import { GenericPostComponent } from '../../../configs/component-types';
import { buildQueryObj } from '../utils';
import { CARD_DEFAULT_QUERY_LIMIT } from '../../../configs/constants';
import { triggerAsycDataExport } from '../../Source/components/ExportSourceQuery/helpers/helpers';
import { ExportPostComponent } from '../PostComponents/components/ExportPostComponent';

/** interface for component export menu  */
export interface ComponentExportMenuProps {
  component: GenericPostComponent;
  postId: string;
}

const makeGetComponentSource = () => getComponentSource;

const ComponentExportMenu: React.FC<ComponentExportMenuProps> = (
  props: ComponentExportMenuProps
) => {
  const { component, postId } = props;
  const componentId = component.id;
  const selectComponentSource = useMemo(makeGetComponentSource, []);
  const [loading, setLoading] = useState(false);
  const [exportComponent, setExportComponent] = useState(false);
  const [csvDownloadLink, setCsvDownloadUrl] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (!csvDownloadLink) return;

    const downloadFile = (url: string) => {
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${component.name}-${Date.now()}`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setCsvDownloadUrl(undefined)
    };

    downloadFile(csvDownloadLink);
  }, [csvDownloadLink]);
  /* @ts-ignore */
  const source = useSelector((state) => selectComponentSource(state, { componentId }));
  const componentQuery =
    component && source?.uuid
      ? buildQueryObj(
          component,
          source.uuid,
          CARD_DEFAULT_QUERY_LIMIT,
          source?.refresh_key,
          source?.cube
        )
      : null;
  const query = {
    cube_query: {
      query: {
        query: {
          ...componentQuery?.query,
          total: false,
        },
        uuid: source?.uuid,
      },
    },
    refresh_key: source?.refresh_key as number,
    cube_name: source?.cube as string,
  };
  const items: MenuProps['items'] = [];

  // add component data export option to exports options
  if (component?.dataExport) {
    items.push({
      key: '1',
      disabled: loading,
      label: (
        <>
          <Space
            onClick={(e) => {
              setLoading(true);
              triggerAsycDataExport(query, setCsvDownloadUrl, setLoading);
              e.stopPropagation();
            }}
          >
            {loading ? <LoadingOutlined /> : <ExportOutlined />}
            {loading ? 'Preparing export...' : 'Export csv'}
          </Space>
        </>
      ),
    });
  }

  // add component image export option to exports options
  if (component?.componentImageExport) {
    items.push({
      key: '2',
      disabled: exportComponent,
      label: (
        <ExportPostComponent
          componentName={component?.title}
          postUrl={window.location.href}
          selector={`#component-${component?.id}`}
          format="png"
          setExportPostComponent={setExportComponent}
          exportComponent={exportComponent}
          postId={postId}
        />
      ),
    });
  }

  return (
    <Dropdown
      menu={{
        items,
      }}
      trigger={['click']}
    >
      <button className="btn-export-options">
        <MoreOutlined />
      </button>
    </Dropdown>
  );
};

export { ComponentExportMenu };
